import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/pro-regular-svg-icons';

import Recaptcha from 'react-recaptcha';

const styles = theme => ({
  field: {
    display: 'block',
    width: '100%',
  },
  input: {
    backgroundColor: '#fff',
    width: '100%',
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit,
      marginBottom: theme.spacing.unit,
    },
  },
  submit: {
    textAlign: 'center',
    marginTop: theme.spacing.unit / 2,
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing.unit,
    },
  },
  submitButton: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit * 2,
    paddingLeft: theme.spacing.unit * 5,
    paddingRight: theme.spacing.unit * 5,
  },
  submitIcon: {
    marginLeft: theme.spacing.unit / 2,
  },
});

class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      recaptchaVerified: false,
      sent: false,
      error: false,
    };
  }

  handleChange = event => {
    const { value, name } = event.target;

    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  handleSubmit = event => {
    event.preventDefault();
    const scriptUrl =
      'https://script.google.com/macros/s/AKfycbwuPA5wonpErZtcnX-1sRDX0RmK3S0VANV04wFFtml0jnD5KhAv/exec';
    const url = `${scriptUrl}?name=${this.state.name}
      &email=${this.state.email}&message=${this.state.message}`;
    fetch(url, {
      mode: 'no-cors',
    }).then(
      () => {
        this.setState({ ...this.state, sent: true });
      },
      () => {
        this.setState({ ...this.state, error: true });
      },
    );
  };

  verifyCallback = () => {
    this.setState({
      ...this.state,
      recaptchaVerified: true,
    });
  };

  onloadCallback = () => {};

  render() {
    const { classes } = this.props;

    if (this.state.sent) {
      return (
        <div className={classNames(classes.alert, classes.success)}>
          <strong>Thank you!</strong>
        </div>
      );
    }

    return (
      <form onSubmit={this.handleSubmit}>
        <FormControl className={classes.field}>
          <TextField
            className={classes.input}
            value={this.state.name}
            required
            label="Name"
            type="text"
            margin="dense"
            variant="outlined"
            inputProps={{
              name: 'name',
              id: 'name',
            }}
            InputLabelProps={{
              htmlFor: 'name',
            }}
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            className={classes.input}
            value={this.state.email}
            required
            label="Email Address"
            type="email"
            margin="dense"
            variant="outlined"
            inputProps={{
              name: 'email',
              id: 'email',
            }}
            InputLabelProps={{
              htmlFor: 'email',
            }}
            onChange={this.handleChange}
          />
        </FormControl>
        <FormControl className={classes.field}>
          <TextField
            className={classes.input}
            value={this.state.message}
            required
            label="How can we help?"
            multiline
            rows="8"
            margin="dense"
            variant="outlined"
            inputProps={{
              name: 'message',
              id: 'message',
            }}
            InputLabelProps={{
              htmlFor: 'message',
            }}
            onChange={this.handleChange}
          />
        </FormControl>
        <Recaptcha
          sitekey="6LcWN58UAAAAADYJohIbRCcedaWhunJrIMxTi3w6"
          render="explicit"
          verifyCallback={this.verifyCallback}
          onloadCallback={this.onloadCallback}
        />
        <FormControl
          variant="filled"
          className={classNames(classes.field, classes.submit)}
        >
          <Button
            type="submit"
            className={classes.submitButton}
            variant="contained"
            disabled={!this.state.recaptchaVerified}
          >
            Submit{' '}
            <FontAwesomeIcon
              icon={faArrowCircleRight}
              className={classes.submitIcon}
            />
          </Button>
        </FormControl>
      </form>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ContactForm);
