import React from 'react';
import { withPrefix } from 'gatsby';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import withRoot from '../withRoot';

import Layout from '../components/Layout';
import container from '../theme/styles/container';
import section from '../theme/styles/section';
import footerChevron from '../theme/styles/footerChevron';
import ContactForm from '../components/ContactForm';

const styles = theme => ({
  ...container,
  ...section,
  ...footerChevron,
  form: {
    marginTop: theme.spacing.unit * 4,
  },
});

const Contact = ({ classes }) => (
  <Layout title="Contact Us">
    <div className={classNames(classes.containerSmall, classes.section)}>
      <Typography component="h1" variant="h1" align="center">
        Contact Us
      </Typography>
      <Typography component="p" variant="subtitle1" align="center">
        Have a question or feedback about your movetrac&reg; experience? Let
        us know! Our moving experts would be happy to help solve any issues you
        may have.
      </Typography>
      <div className={classes.form}>
        <ContactForm />
      </div>
    </div>
    <div className={classes.footerChevronContainer}>
      <img
        src={withPrefix('/img/bottom--alt.svg')}
        alt=""
        className={classes.footerChevron}
      />
    </div>
  </Layout>
);

export default withRoot(withStyles(styles)(Contact));
